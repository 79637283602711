// stylelint-disable max-nesting-depth
$scl-v-sidebar-userinfo--bg-color: map-get($scl-color-brand, 'primary') !default;

.scl-o-navbar__toggle-sidebar {
    display: none;

    @include to-md {
        display: inline-block;
    }
}


.v-sidebar {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .scl-b-app__row {
        align-items: center;
    }

    .scl-b-app__row-collapsed {
        align-items: center;

        @include mq('sm') {
            align-items: flex-end;
        }
    }

    .scl-o-navbar__stack--right {
        margin-left: auto;
    }

    &__menu {
        width: auto;
        flex: 1;

        &-content {
            flex-grow: 1;
            justify-content: flex-start;
        }
    }

    &__title {
        @include heading-style;
        @include h2;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
        display: flex;
        flex: 1;
        align-items: center;
        text-align: right;
        padding-top: map-get($scl-spacers, 4);
        padding-bottom: map-get($scl-spacers, 4);
        align-self: flex-end;
    }

    &__logo {
        display: flex;
        flex: 1;
        align-content: center;
        align-self: stretch;
        padding-top: map-get($scl-spacers, 4);
        padding-bottom: map-get($scl-spacers, 4);

        &--app {
            max-width: 85%;
            width: 200px;
            margin: 0 auto;
        }

        &--vwpfs {
            max-width: 65%;
            max-height: 50px;
            display: block;
            margin: 0 auto;
            margin-bottom: map-get($scl-spacers, 1);
        }

        &--claude {
            width: 65%;
            max-width: 70px;
            height: 28px;
            display: block;
            margin: 0 auto;
        }

        @include mq('sm') {
            &--app {
                margin-right: map-get($scl-spacers, 3);
                margin-left: auto;
            }
        }
    }

    &__version {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-right: map-get($scl-spacers, 3);
        padding-left: map-get($scl-spacers, 3);

        &-collapsed {
            display: flex;
            flex: 1;
            padding-right: map-get($scl-spacers, 3);
            padding-left: map-get($scl-spacers, 1);

            .scl-a-badge {
                line-height: 1.2em;
                @include brand-accent {
                    background-color: map-get($scl-color-special-brand, 'first');
                    color: map-get($scl-color-special-contrast, 'first');

                    &:hover,
                    &:focus,
                    &:active {
                        color: map-get($scl-color-special-contrast, 'first');
                    }
                }
            }
        }

        .scl-m-release-version,
        &-collapsed {
            padding-top: map-get($scl-spacers, 2);
            padding-bottom: map-get($scl-spacers, 3);
        }

        .scl-a-badge {
            line-height: 1.2em;
            @include brand-accent {
                background-color: map-get($scl-color-special-brand, 'first');
                color: map-get($scl-color-special-contrast, 'first');

                &:hover,
                &:focus,
                &:active {
                    color: map-get($scl-color-special-contrast, 'first');
                }
            }
        }
    }

    &__userinfo {
        z-index: 3;
        background-color: rgba($scl-v-sidebar-userinfo--bg-color, .75);
        box-shadow: 0 4px 14px -8px rgba(0, 0, 0, .2);
    }
}
